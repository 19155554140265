import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
const initialState = {
  stats: [],
  status: "idle",
  error: null,
};

export const fetchGeneralStats = createAsyncThunk(
  "stats/fetchGeneralStats",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(`/websites/general-stats`, {
        params: { websiteID },
      });
      data = response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "stats",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGeneralStats.pending]: (state) => {
      state.status = "loading";
    },
    [fetchGeneralStats.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.stats = action.payload.data.result;
    },
    [fetchGeneralStats.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const { setNewAvatar, emptyAvatar } = slice.actions;
export const reducer = slice.reducer;
export default slice;
