import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
const initialState = {
  offers: [],
  features: [],
  meta: {},
  status: "idle",
  error: null,
  statusGetFeatures: "idle",
};
export const fetchOffers = createAsyncThunk(
  "offers/fetchOffers",
  async (body) => {
    let data;

    try {
      const response = await axios.get(
        `/offers?page=${body?.page || 1}&limit=${body?.rowsPerPage || 20}`
      );
      data = await response.data;
      if (response.status === 200) {
        const { docs, ...rest } = response.data?.data;
        return { docs, meta: rest };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message);
    }
  }
);
export const createOffer = createAsyncThunk(
  "offers/createOffer",
  async (body, thunkAPI) => {
    const values = {
      ...body,
      features: body?.features
        ?.map((feature) => {
          return feature?.customNumber
            ? {
                ...feature,
                number:
                  feature?.customNumber === 1 ? -1 : feature?.customNumber,
                customNumber: undefined,
              }
            : {
                ...feature,
                number: feature?.number === 1 ? -1 : feature?.number,
              };
        })
        ?.filter((feature) => feature.number !== 0),
    };
    let data;
    try {
      const response = await axios.post(`/offers`, values);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchOffers());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message);
    }
  }
);
export const updateOffer = createAsyncThunk(
  "offers/updateOffer",
  async (body, thunkAPI) => {
    const values = {
      ...body,
      pricePerMonth: body?.isFree ? 0 : body.pricePerMonth,
      pricePerYear: body?.isFree ? 0 : body?.pricePerYear,
      features: body?.features
        ?.map((feature) => {
          return feature?.customNumber
            ? {
                ...feature,
                number:
                  feature?.customNumber === 1 ? -1 : feature?.customNumber,
                customNumber: undefined,
              }
            : {
                ...feature,
                number: feature?.number === 1 ? -1 : feature?.number,
              };
        })
        ?.filter((feature) => feature.number !== 0),
    };
    delete values?.offerId;
    let data;
    try {
      const response = await axios.patch(`/offers/${body?.offerId}`, values);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchOffers());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message);
    }
  }
);

export const deleteOffer = createAsyncThunk(
  "offers/deleteOffer",
  async (offerId, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/offers/${offerId}`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchOffers());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message);
    }
  }
);

export const getFeatures = createAsyncThunk("offers/getFeatures", async (_) => {
  let data;
  try {
    const response = await axios.get(`/admin/features`);
    data = await response.data;
    if (response.status === 200) {
      return data.data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message);
  }
});

const slice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    getItem: (state, action) => {
      state.item = action.payload;
    },
  },
  extraReducers: {
    [fetchOffers.pending]: (state) => {
      state.status = "loading";
    },
    [fetchOffers.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.offers = action.payload?.docs;
      state.meta = action.payload?.meta;
    },
    [fetchOffers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [getFeatures.pending]: (state) => {
      state.statusGetFeatures = "loading";
    },
    [getFeatures.fulfilled]: (state, action) => {
      state.features = action.payload;
      state.statusGetFeatures = "success";
    },
    [getFeatures.rejected]: (state) => {
      state.statusGetFeatures = "failed";
    },
  },
});
export const reducer = slice.reducer;
export const { getItem } = slice.actions;

export default slice;
