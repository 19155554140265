import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";
const initialState = {
  subscriptions: [],
  status: "idle",
  meta: {},
  error: null,
};
export const fetchSubscriptions = createAsyncThunk(
  "subscriptions/fetchSubscriptions",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/subscriptions?page=${body.page}&limit=${body?.rowsPerPage}`
      );
      data = await response.data.data;
      if ((response.status = 200)) {
        const { docs, ...rest } = data;
        return { docs, meta: rest };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message);
    }
  }
);

export const editSubscription = createAsyncThunk(
  "subscriptions/editSubscription",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(
        `/websites/subscriptions/${body?.subscriptionId}`,
        {
          expiresAt: body?.expiresAt,
          isActive: body?.isActive,
          extraFeatures: body?.extraFeatures
            ?.map((feature) => {
              return feature?.customNumber
                ? {
                    ...feature,
                    number:
                      feature?.customNumber === 1 ? -1 : feature?.customNumber,
                    customNumber: undefined,
                  }
                : {
                    ...feature,
                    number: feature?.number === 1 ? -1 : feature?.number,
                  };
            })
            ?.filter((feature) => feature.number !== 0),
        }
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message);
    }
  }
);

const slice = createSlice({
  name: "subscriptions",
  initialState,
  extraReducers: {
    [fetchSubscriptions.pending]: (state) => {
      state.status = "loading";
    },
    [fetchSubscriptions.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.subscriptions = action.payload.docs;
      state.meta = action.payload.meta;
    },
    [fetchSubscriptions.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export const {} = slice.actions;

export default slice;
