import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../../utils/axios";

const initialState = {
  averageSessionDuration: [],
  totalAverageDuration: null,
  statusAverageSessionDuration: "idle",
  averageMessagesPerSession: [],
  totalAverageMessages: null,
  statusAverageMessagesPerSession: "idle",
  retentionRate: [],
  totalRetentionRate: null,
  statusRetentionRate: "idle",
  aiPerformanceRate: [],
  totalAiSuccessRate: null,
  totalAiFailureRate: null,
  statusAiPerformanceRate: "idle",
  statusEscalationRate: "idle",
  totalEscalationRate: null,
  escalationRate: [],
  usersFeedbackRate: [],
  statusUsersfeedbackRate: "idle",
  averageUsersFeedbackPercentage: null,
  totalAverageBotResponseTime: null,
  statusBotResponseTime: "idle",
  averageBotResponseTime: [],
};

export const getAverageSessionDuration = createAsyncThunk(
  "analytics/getAverageSessionDuration",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${
          body?.websiteID
        }/statistics/average-session-duration?year=${body?.year?.getFullYear()}`,
        {
          params: {
            month: body?.month?.getMonth(),
          },
        }
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAverageMessagesPerSession = createAsyncThunk(
  "analytics/getAverageMessagesPerSession",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${
          body?.websiteID
        }/statistics/average-messages-per-session?year=${body?.year?.getFullYear()}`,
        {
          params: {
            month: body?.month?.getMonth(),
          },
        }
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getRetentionRate = createAsyncThunk(
  "analytics/getRetentionRate",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${
          body?.websiteID
        }/statistics/retention-rate?year=${body?.year?.getFullYear()}`,
        {
          params: {
            month: body?.month?.getMonth(),
          },
        }
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAiPerformanceRate = createAsyncThunk(
  "analytics/getAiPerformanceRate",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${
          body?.websiteID
        }/statistics/ai-performance-rate?year=${body?.year?.getFullYear()}`,
        {
          params: {
            month: body?.month?.getMonth(),
          },
        }
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getEscalationRate = createAsyncThunk(
  "analytics/getEscalationRate",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${
          body?.websiteID
        }/statistics/escalation-rate?year=${body?.year?.getFullYear()}`,
        {
          params: {
            month: body?.month?.getMonth(),
          },
        }
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getUserFeedbackRate = createAsyncThunk(
  "analytics/getUserFeedbackRate",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${
          body?.websiteID
        }/statistics/users-feedback?year=${body?.year?.getFullYear()}`,
        {
          params: {
            month: body?.month?.getMonth(),
          },
        }
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getBotResponseTime = createAsyncThunk(
  "analytics/getBotResponseTime",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${
          body?.websiteID
        }/statistics/bot-response-time?year=${body?.year?.getFullYear()}`,
        {
          params: {
            month: body?.month?.getMonth(),
          },
        }
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: {
    [getAverageSessionDuration.pending]: (state) => {
      state.statusAverageSessionDuration = "loading";
    },
    [getAverageSessionDuration.fulfilled]: (state, action) => {
      state.statusAverageSessionDuration = "success";
      state.averageSessionDuration = action.payload.averageSessionDuration;
      state.totalAverageDuration = action.payload.totalAverageDuration;
    },
    [getAverageSessionDuration.rejected]: (state) => {
      state.statusAverageSessionDuration = "failed";
    },
    [getAverageMessagesPerSession.pending]: (state) => {
      state.statusAverageMessagesPerSession = "loading";
    },
    [getAverageMessagesPerSession.fulfilled]: (state, action) => {
      state.statusAverageMessagesPerSession = "success";
      state.averageMessagesPerSession =
        action.payload.averageMessagesPerSession;
      state.totalAverageMessages = action.payload.totalAverageMessages;
    },
    [getAverageMessagesPerSession.rejected]: (state) => {
      state.statusAverageMessagesPerSession = "failed";
    },
    [getRetentionRate.pending]: (state) => {
      state.statusRetentionRate = "loading";
    },
    [getRetentionRate.fulfilled]: (state, action) => {
      state.statusRetentionRate = "success";
      state.retentionRate = action.payload.retentionRate;
      state.totalRetentionRate = action.payload.totalRetentionRate;
    },
    [getRetentionRate.rejected]: (state) => {
      state.statusRetentionRate = "failed";
    },
    [getAiPerformanceRate.pending]: (state) => {
      state.statusAiPerformanceRate = "loading";
    },
    [getAiPerformanceRate.fulfilled]: (state, action) => {
      state.statusAiPerformanceRate = "success";
      state.aiPerformanceRate = action.payload.aiPerformanceRate;
      state.totalAiSuccessRate = action.payload.totalAiSuccessRate;
      state.totalAiFailureRate = action.payload.totalAiFailureRate;
    },
    [getAiPerformanceRate.rejected]: (state) => {
      state.statusAiPerformanceRate = "failed";
    },
    [getEscalationRate.pending]: (state) => {
      state.statusEscalationRate = "loading";
    },
    [getEscalationRate.fulfilled]: (state, action) => {
      state.statusEscalationRate = "success";
      state.escalationRate = action.payload.escalationRate;
      state.totalEscalationRate = action.payload.totalEscalationRate;
    },
    [getEscalationRate.rejected]: (state) => {
      state.statusEscalationRate = "failed";
    },
    [getUserFeedbackRate.pending]: (state) => {
      state.statusUsersfeedbackRate = "loading";
    },
    [getUserFeedbackRate.fulfilled]: (state, action) => {
      state.statusUsersfeedbackRate = "success";
      state.usersFeedbackRate = action.payload.usersFeedback;
      state.averageUsersFeedbackPercentage =
        action.payload.averageUsersFeedbackPercentage;
    },
    [getUserFeedbackRate.rejected]: (state) => {
      state.statusUsersfeedbackRate = "failed";
    },
    [getBotResponseTime.pending]: (state) => {
      state.statusBotResponseTime = "loading";
    },
    [getBotResponseTime.fulfilled]: (state, action) => {
      state.statusBotResponseTime = "success";
      state.averageBotResponseTime = action.payload.averageBotResponseTime;
      state.totalAverageBotResponseTime =
        action.payload.totalAverageBotResponseTime;
    },
    [getBotResponseTime.rejected]: (state) => {
      state.statusBotResponseTime = "failed";
    },
  },
});
export const {} = slice.actions;
export const reducer = slice.reducer;
export default slice;
