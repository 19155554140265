import { combineReducers } from "@reduxjs/toolkit";
import { reducer as websitesReducer } from "./../redux/slices/websites";
import { reducer as snackbarReducer } from "./slices/snackbar";
import { reducer as superAdminsReducer } from "./slices/SuperAdmins";
import { reducer as userReducer } from "./slices/user";
import { reducer as offersReducer } from "./slices/offers";
import { reducer as subscriptionsReducer } from "./slices/subscriptions";
import { reducer as statsReducer } from "./slices/stats";
import { reducer as analyticsReducer } from "./slices/analytics";

const combinedReducer = combineReducers({
  websites: websitesReducer,
  snackbar: snackbarReducer,
  superAdmins: superAdminsReducer,
  user: userReducer,
  offers: offersReducer,
  subscriptions: subscriptionsReducer,
  stats: statsReducer,
  analytics: analyticsReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
